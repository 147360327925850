<template>
    <div>
        <p class="title">RFP Questionnaire</p>
        <DataTable
            :value="products"
            :paginator="true"
            :rows="5"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            :rowsPerPageOptions="[5, 10, 20]"
            responsiveLayout="scroll"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        >
            <Column field="id" header="Question id"></Column>
            <Column field="rfp_question_text" header="RFP Question text"></Column>
            <Column field="display_name" header="Title"></Column>
            <Column field="category_name" header="Category"></Column>
            <Column field="response_type" header="Response type"></Column>
            <Column field="status" header="Status"></Column>
        </DataTable>
    </div>
</template>

<script>
import ProductService from '../service/RfpService';
import { onMounted } from '@vue/runtime-core';
export default {
    data() {
        return {
            products: null,
        };
    },
    productService: null,
    created() {
        this.productService = new ProductService();
        onMounted(() => {
            this.getQuestions();
        });
    },
    methods: {
        async getQuestions() {
            var questionData = await this.productService.getRfpQuesionaire();
            questionData.data.forEach((element) => {
                element.id = String(element.id).padStart(5, 0);
                element.id = `Q${element.id}`;
                element.status = element.status ? 'active' : '';
            });
            this.products = questionData.data;
        },
    },
};
</script>

<style lang="scss"></style>
