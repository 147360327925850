import { getApi } from './common/apis';

export default class RfpQuestionnaireService {

    async getRfpQuesionaire() {
        var questions = await getApi('v1/rfp-questionnaire')
        return questions
    }

}
